.app {
    .ant-layout-sider {
        background: black;
    }    
    .site-layout {
        &-background {
            background-color: white;
            justify-content: space-between;
        }
        .ant-layout-header {
            background-color: black;
            display: flex;
            align-items: center;
            padding: 0 16px;
            .ant-typography {
                margin: 0;
                color: white;
            }
        }
    }
    .ant-layout {
        height: 100vh;
        overflow: hidden;
        &-content {
            height: 100%;
            overflow: auto;
        }
        &-sider-trigger {
            background-color: black;
        }
    }
    &__layout {
        margin: 0 16px;
        display: flex;
        flex-direction: column;
    }
}

.page {
    &__header {
        margin: 16px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__wrapper {
        height: 100%;
        overflow: auto;
        position: relative;
    }
    &__footer {
        margin: 10px;
        text-align: center;
        color: rgba(0,0,0,.5);
    }
}


.logo {
    height: 32px;
    margin: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.layout {
    .header {
        &__right {
            display: flex;
            align-items: center;
            .user {
                span {
                    color: white;
                    display: inline-block;
                }
                .ant-avatar  {
                    margin: 0 .5rem 0 0;
                }
            }
        }
    }
}

.header {
    &__right {
        display: flex;
        align-items: center;
        .ant-btn {
            margin: 0 0 0 10px;
            &:first-child {
                margin: 0;
            }
        }
    }
    &__notify {
        margin-right: .5rem !important;
        button {
            padding: 0;
            background: transparent;
            border: none;
            outline: none;
            &:hover {
                .anticon {
                    color: #69bfdc;
                }
            }
            .anticon {
                font-size: 1.5rem;
                cursor: pointer;
                transition: ease-in .2s;
            }
        }
    }
}

@media(max-width: 991px) {
    .app {
        &__main-title {
            font-size: 1.25rem !important;
            line-height: 1 !important;
        }
    }
    .page {
        .ant-divider-horizontal.ant-divider-with-text {
            margin-top: 0;
            &::before, &::after {
                top: 0;
            }
        }
    }
    .ant-layout {
        min-height: unset;
        height: 100vh;
    }
    .header {
        &__right {
            display: flex;
        }
    }
}