// :root {
//     --primary-color: #94C11F;
// }

body {
    background-color: #F0F2F5;
}

.ant-table-container table > thead > tr:first-child th:last-child {
    text-align: right;
}

.ant-table-tbody > tr > td:last-child {
    text-align: right;
}

.app {
    &__confirm {
        .ant-modal-confirm-body {
            .ant-result {
                padding: 28px 32px;
            }
        }
        .ant-modal-confirm-btns {
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            justify-content: center;
            margin: 0 0 48px 0!important;
            .ant-btn {
                margin: 0 8px;
            }
        }
    }
}