.auth {
    &.page {
        max-width: 320px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        .ant-tabs {
            width: 100%;
        }
    }
    &__logo {
        display: block;
        margin: 30px auto;
        width: 90px;
    }
    &__btn {
        width: 100%;
    }
    &__actions {
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: space-between;
        }
    }
    &__code {
        margin: 0 !important;
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: space-between;
        }
    }
}