.profile {
    &.page {
        padding: 30px;
    }
    &__avatar {
        width: 100%;
        max-width: 230px;
        height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fafafa;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 120px;
        border-radius: 100%;
        margin: 10px 0 0 0;
        border: 0;
        cursor: pointer;
        position: relative;
        &_custom {
            background-size: cover;
        }
        &::before {
            opacity: 0;
            content: 'Изменить';
            position: absolute;
            border-radius: 100%;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0,0,0,.4);
            color: white;
            transition: linear .25s;
        }
        &:hover {
            &::before {
                opacity: 1;
            }
        }
        svg {
            width: 50%;
            height: auto;
            path {
                fill: #94C11F;
            }
        }
    }
    .badges {
        &__items {
            display: flex;
            align-items: center;
            padding-left: 15px;
            svg {
                margin: 0 0 0 -15px;
                box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, .2);
                border-radius: 100%;
                &:first-child {
                    margin: 0;
                }
            }
        }
    }
    &__form-item {
        &.ant-form-item {
            margin-bottom: 0;
            .ant-picker {
                width: 100%;
            }
        }
    }
    &__form-group {
        row-gap: 10px !important;
    }
    &__form {
        display: flex;
        gap: 30px;
    }
}