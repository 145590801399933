.preloader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 100;
    &.fullscreen {
        position: fixed;
    }
    &.fullpage {
        position: absolute;
        left: 0;
        top: 0;
    }
}