.runs {
    &__modal {
        .ant-modal-footer {
            display: flex;
            justify-content: space-between;
        }
        &.start, &.edit {
            .ant-modal-footer {
                justify-content: flex-end;
            }
        }
        &.add-results {
            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
}