.workout {
    &.page {
        padding: 30px;
    }
    &__types {
        display: flex;
        justify-content: space-between;
    }
    &__events {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .types {
        margin-bottom: 1rem;
        &__item {
            width: 18%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 150px;
            background: #F0F0F0;
            border: none;
            transition: ease-in-out .3s;
            cursor: pointer;
            &:nth-child(4) {
                svg {
                    path {
                        stroke: #95C11F;
                        fill: transparent;
                    }
                }
                &:hover {
                    svg {
                        path {
                            stroke: white;
                            fill: transparent;
                        }
                    }
                }
            }
            svg {
                height: 60px;
                path {
                    fill: #95C11F;
                    stroke: transparent;
                    transition: ease-in-out .3s;
                }
            }
            span {
                font-weight: bold;
                font-size: 1rem;
                display: inline-block;
                margin: 10px 0 0 0;
            }
            &:hover, &.selected {
                background: #95C11F;
                svg {
                    path {
                        fill: white;
                        stroke: transparent;
                    }
                }
            }
        }
    }
    .calendar {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 0;
            .ant-select-selection-item {
                text-transform: capitalize;
            }
        }
        .ant-picker-body {
            padding: 0 !important;
        }
    }
}

// Dropdown modals

.calendar {
    &__select-month {
        .ant-select-item-option-content {
            text-transform: capitalize;
        }
    }
}

// Modals

.trains-day {
    &__head {
        display: flex;
        align-items: center;
        background-color: #F0F2F5;
        height: 56px;
        margin: 0 !important;
        svg {
            width: 30px;
            height: 30px;
            margin: 0 15px;
        }
    }
    .ant-table-wrapper {
        margin: 0 0 30px 0;
    }
    .ant-table-container table > thead > tr:first-child th:last-child,
    .ant-table-tbody > tr > td:last-child {
        text-align: left;
    }
}